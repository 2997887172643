<template>
  <b-modal
    id="add-schedule"
    size="xl"
    title="Create Payment Schedule"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <!-- body-class="pageheight-80" -->
    <b-container>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-container v-show="loading">
          <b-row class="d-flex justify-content-center mb-3">
            <b-spinner
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>
          </b-row>
        </b-container>
        
        <!-- sticky-header="62vh" -->
        <b-table
          v-show="(users?users:[]).length > 0 && !loading"
          :fields="visibleFields"
          :items="users"
          sticky-header="62vh"
          hover
          select-mode="multi"
          head-variant="light"
          ref="addScheduleTable"
          responsive
          selectable
          selected-variant="success"
          @row-selected="on_row_selected"
          no-select-on-click
        >
        <!-- @row-selected="on_row_selected" -->
          <template #head(selected)>
            <b-form-checkbox @change="toggle_all" v-model="select_all">
            </b-form-checkbox>
          </template>
          <template #head(withdrawal)>
            Withdrawal Charge
            <b-checkbox v-model="include_withdrawal">
            </b-checkbox>
          </template>
          <template #head(withdrawal_tax)>
            Withdrawal Tax
            <b-checkbox  v-model="include_tax">
            </b-checkbox>
          </template>
          <template bordered #cell(name)="data">
            <b-avatar icon="person">{{ data.item.name[0] }}</b-avatar>
            {{ data.item.name }}
          </template>
          <template #cell(contact)="data">
            <span class="text-secondary">{{ data.item.contact }}</span>
          </template>
          <template #cell(sending)="data">
            <span v-text="get_sending_charge(data.item.payment,data.item.ac_number,data.item.method).toLocaleString()"
            >
            </span>
          </template>
          <template #cell(withdrawal)="data">
            <span 
            :class="{ 'font-weight-lighter': !include_withdrawal}"
            v-text="get_withdrawal_charge(data.item.payment,data.item.ac_number,data.item.method).toLocaleString()"
            >
            </span>
          </template>
          <template #cell(withdrawal_tax)="data">
            <span 
            :class="{ 'font-weight-lighter': !include_tax}"
            v-text="get_tax_charge(data.item.payment,data.item.ac_number,data.item.method).toLocaleString()">
            
            </span>
          </template>
          <template #cell(ac_number)="data">
            {{data.item.ac_number}}
            <span v-if="!data.item.ac_number" class="text-danger">
              Missing Payment Information!
            </span>
          </template>
          <template #cell(method)="data">
            <span v-if="!view_charges || data.item.ac_number" class="text-secondary">
              {{ data.item.method }}
              
            </span>
            <span v-if="view_charges && !data.item.ac_number" class="text-danger">
              Missing Payment Information
              
            </span>
          </template>
          <template #cell(edit)="data">
            <b-button size="sm" variant="light" @click="view_user(data)">
              <b-icon-pencil-fill></b-icon-pencil-fill>
            </b-button>
          </template>

          <template #cell(amount)="data">
            <strong>{{
              Number(data.item.pending_balance).toLocaleString()
            }}</strong>
          </template>
          <template #cell(payment)="data">
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-input
                    v-model="data.item.payment"
                    type="number"
                    required
                    @change="data.item.payment<=0?select_row(false,data.index):true"
                    :disabled="!can('create_schedule', 'payments')"
                    
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <template #cell(net_balance)="data">
            <span class="text-muted">
              {{
                (data.item.pending_balance - data.item.payment).toLocaleString()
              }}
            </span>
          </template>
           <template #cell(selected)="data">
            <b-form-checkbox
            :disabled="!(data.item.payment>0 && data.item.ac_number)"
            v-model="data.rowSelected"
            @change="select_row($event, data.index)" 
            >

            </b-form-checkbox>
          </template>
        </b-table>
        <b-row>
          <!-- <b-col>
            <h6 class="text-muted">Total farmers:</h6>
            <h6>{{ (users?users:[]).length }}</h6>
          </b-col>
          <b-col class="border-right">
            <h6 class="text-muted">Total Amount:</h6>
            <h6>
              <span>{{ currency }}&nbsp;</span
              ><span class="text-muted">{{ total_amount(users).toLocaleString() }}</span>
            </h6>
          </b-col> -->
         
          <b-col  >
            <p class="text-primary  border-bottom">
             
               Selected Amount:

              <span class="float-right text-sm-right  float-right">
              <span class="small">{{ currency }}&nbsp;</span>
              <span class="text-primary">{{ total_amount(selected).toLocaleString() }}</span>
              </span>
              <!-- ><span class="text-success">{{ total_amount(return_selected()) }}</span> -->
            </p>
            <p class="text-secondary border-bottom">Sending Charge:
            <span class="float-right">
              <span>{{ currency }}&nbsp;</span
              ><span class="text-secondary">{{ total_sending(selected).toLocaleString() }}</span>
              <!-- ><span class="text-success">{{ total_amount(return_selected()) }}</span> -->
            </span>
            </p>
            <p class="text-secondary text-sm-left border-bottom">Withdrawal Charge:
              <span class="edit"> 
              <b-checkbox v-model="include_withdrawal"></b-checkbox>
              </span>
            <span class="float-right"
            :class="{ 'font-weight-lighter': !include_withdrawal}"
            >
              <span>{{ currency }}&nbsp;</span
              ><span class="text-secondary">{{ total_withdrawal(selected).toLocaleString() }}</span>
              <!-- ><span class="text-success">{{ total_amount(return_selected()) }}</span> -->
            </span>
            </p>
            <p class="text-secondary border-bottom">Withdrawal Tax:
             <span class="edit"> 
               <b-checkbox v-model="include_tax">
              </b-checkbox></span>
            <span class="float-right"
            :class="{ 'font-weight-lighter': !include_tax}"
            >
              <span>{{ currency }}&nbsp;</span
              ><span class="text-secondary" 
              
              >{{ total_tax(selected).toLocaleString() }}</span>
              <!-- ><span class="text-success">{{ total_amount(return_selected()) }}</span> -->
            </span>
            </p>
            <p class="text-secondary border-bottom">Total:
            <span class="float-right">
              <span>{{ currency }}&nbsp;</span
              ><span class="text-success">{{ total(selected).toLocaleString() }}</span>
              <!-- ><span class="text-success">{{ total_amount(return_selected()) }}</span> -->
            </span>
            </p>
            <button id="selectall" hidden  @click.prevent="select_all_rows"></button>
          </b-col >
           <b-col class="float-right" >

            <div class="text-primary text-right">Selected farmers:</div>
            <h6 class="text-right">{{ selected.length }}</h6>
            <h6 class="pb-6 text-right" v-if="!loading && withdrawal.length">
              
              <b-checkbox class="text-right"  v-model="view_charges"  button button-variant="outline-primary">
              <span v-if="!view_charges">View Charges</span>
              <span v-if="view_charges">View Payment Info</span>
              </b-checkbox>

            </h6>
            <small>&nbsp;</small>
            <small>&nbsp;</small>
            <h6 class="pt-6 "
            v-show="can('create_schedule', 'payments') && !loading"

            >
              <b-button
              variant="primary"
              class="float-right"
              :disabled="selected.length == 0 || total_amount(selected) <= 0"
              @click="add_payment_schedule"
              v-analytics:click="['add_payment_schedule',{element:'Create Payment Schedule'}]"
              >Create Payment Schedule</b-button
            >
              </h6>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
          </b-col>
          <b-col
            
          >

          </b-col>
        </b-row>
        <b-container v-show="loading">
          <b-row class="d-flex justify-content-center mb-3">
            <span class="font-weight-light text-muted"
              >Processing...&nbsp;&nbsp;</span
            >
            <b-spinner variant="secondary" label="Loading..."></b-spinner>
          </b-row>
        </b-container>
        <b-container v-show="error">
          <b-alert class="justify-content-center" variant="danger"
            >Failed!!!</b-alert
          >
        </b-container>
        <b-container v-show="success">
          <b-alert class="justify-content-center" variant="success" show
            >Successful!!</b-alert
          >
        </b-container>
      </b-form>
    </b-container>
    <pay-farmer @SuccessfulPayment="edit_farmer"></pay-farmer>
  </b-modal>
</template>
<script>
import {
  add_payment_schedule,
  get_produce_pending_payments,
  on_row_selected,
  select_all_rows,
  select_row,
  clear_selected,
  toggle_all, get_charges,
  get_sending_charge,
  get_withdrawal_charge,
  get_tax_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
  // get_network

} from "./actions.js";
import { can } from "@/modules/auth/utils.js";
import PayFarmer from '@/components/pay_farmer/PayFarmer.vue';
import { bus } from "@/main.js";
export default {
  components: {PayFarmer},
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
    visibleFields() {
        return this.fields.filter(field => this.view_charges?
        !this.non_charge_fields.includes(field.key):
          !this.charge_fields.includes(field.key)
          
        )
    },
    currentRoute() {
        return this.$route.name;
    }
  },
  data() {
    let view_charges=false;
    return {
      form: {
        name: "",
        gender: "",
        phone: "+256",
        district: "",
        village: "",
      },
      view_charges,
      money:"mobile",
      account:"ac_number",
      users: [],
      currency: "UGX",
      select_all: "false",
      gender: [{ text: "Select One", value: null }, "male", "female"],
      districts: [],
      loading: false,
      fields: [
        { key: "farmer_name", label: "Name",},
        { key: "ac_name", label: "a/c name",},
        { key: "ac_number", label: "a/c number" },
        { key: "method", label: "Method"},
        { key: "edit", label:"", tdClass: 'edit',thClass:'edit' },
        { key: "amount", label: "Amount Due", },
        { key: "payment", label: "Payment",  },
        { key: "net_balance", label: "Unpaid",},
        { key: "sending", label: "Sending Charge",  },
        { key: "withdrawal", label:"Withdrawal Charge", },
        { key: "withdrawal_tax", label:"Withdrawal Tax",tdClass: 'pr-0',thClass:'pr-0'  },
        { key: "selected", label: "", tdClass: 'edit',thClass:'edit' },
        

        
      ],
      selected: [],
      withdrawal:[],
      sending:[],
      tax:[],
      charge_fields:['withdrawal','withdrawal_tax','sending'],
      non_charge_fields:['ac_name','ac_number'],
      success:false,
      error:false,
      include_tax:true,
      include_withdrawal:true,
    };
  },

  methods: {
    can,
    onSubmit() {},
    onReset() {},
    add_payment_schedule,
    on_row_selected,
    get_withdrawal_charge,
    get_sending_charge,
    get_tax_charge,
    select_all_rows,
    clear_selected,
    get_produce_pending_payments,
    toggle_all,
    select_row,
    get_charges,
    return_selected() {
      return this.users.filter((user) => this.is_selected(user.selected));
    },
    total,
    total_withdrawal,
    total_amount,
    total_sending,
    total_tax,
    is_selected(value) {
      return (value ? value.toString() : "") == "true";
    },
    log(value) {
      console.log(value);
    },
    view_user(item) {
      this.index = item.index;
      this.selected_item = item.item;
      bus.$emit("pay-farmer", item.item);
    },
    edit_farmer(obj){
      this.selected_item['ac_name'] = obj.ac_name;
      this.selected_item['ac_number']= obj.ac_number;
      this.selected_item.method= obj.method
      this.$emit('SuccessfulPayment');
    }
  },
  created() {
    this.get_charges();
    bus.$on("add-payment-schedule", (payload) => {
      // console.log(add-)
      this.$bvModal.show("add-schedule");
      setTimeout(() => {
      if (payload.load == true && ((this.users?this.users:[]).length <= 0)) {
        
          this.get_produce_pending_payments();

      } else if (payload.items) {

        this.users = payload.items;
      }
      this.selected = [];
      
      
      this.select_all = false;
      this.select_all = payload.all;
      this.select_all?
      setTimeout(() => {
      document.getElementById('selectall').click()}, 500):0;
      }, 500)
      
      
      
      
      
    });
    
  },
};
</script>

<style>
.height-20 {
  max-height: 20vh;
}
.height-100 {
  height: 100%;
}
.pageheight-80 {
  height: calc(80vh);
  overflow-y: auto;
}
.text-primary {
  color: #004085 !important;
}
input.form-control{
 min-width: 120px;
}

.table tbody .edit {
  max-width: 20px !important;
  padding:0px !important;
  margin:0px !important;
}
.table thead .edit {
  max-width: 20px !important;
  padding-left:0px !important;
  margin:0px !important;
}
span.edit{
  max-width: 20px;
}
span.edit > .custom-control {
  max-width: 20px;
  display:inline;
}
</style>
